import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Education",
        tab3 = "Experience",
        tab4 = "Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Electronic Solutions <span> - Engineering</span></a>
                                                    Figuring out implementing feature / Solving Electronic problems.
                                                </li>
                                                <li>
                                                    <a href="/service">Browser and Web Automation<span> - Development</span></a>
                                                    Developing a software to do particular tasks.
                                                </li>
                                                <li>
                                                    <a href="/service">Python Solutions<span> - Development</span></a>
                                                    Producing desired service using Python.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service"> Alanya Alaadding Keykubat University <span>- Prep Year</span></a> 2018 - 2019
                                               </li>
                                               <li>
                                                   <a href="/service">Kaunas Technical College<span>- Exchange Student</span></a> 2020 - 2021
                                               </li>
                                               <li>
                                                   <a href="/service">Karabuk University<span>- Bachelor of Science</span></a> 2019- 2023
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Supervisor<span> - Mondelēz International</span></a> 05/2022 - 06/2022
                                               </li>
                                               <li>
                                                   <a href="/service">Intern<span> - Flexkon Konveyor</span></a> 06/2022 - 08/2022
                                               </li>
                                               <li>
                                                   {/*<a href="/service">Software Engineer<span> - Alibaba </span></a> 2013- 2014*/}
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Diploma in Electronics Engineering<span> - Karabuk University, Turkey</span></a> 2023
                                               </li>
                                               <li>
                                                   <a href="/service">Computer Science<span> - Harvard / EDX</span></a> 2021 Credentials: https://courses.edx.org/certificates/68bc0ee1beff4f959a61fde6b0299d12
                                               </li>
                                               <li>
                                                   <a href="/service">Computer Science with Python<span> - Harvard / EDX</span></a> 2022 Credentials: https://courses.edx.org/certificates/d4992eae26204473ae7bc1d186f814fc
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;