import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Web Automation',
        description: 'I can automate repetitive and time-consuming tasks on websites, such as data entry, form submissions, and click-throughs, using scripts or software programs. This service can help businesses streamline their online processes, increase efficiency, and reduce the risk of errors.'
    },
    {
        icon: <FiLayers />,
        title: 'Microcontroller Programming',
        description: 'I can write software/firmware for microcontrollers such as Arduino, PIC, AVR, etc. Additionally, I can offer IoT and embedded systems development services to help businesses and individuals create efficient and customized solutions for their projects using microcontroller technology'
    },
    {
        icon: <FiUsers />,
        title: 'Python Developing',
        description: "I can develop of software for data analysis and visualization, automation and scripting, and Internet of things and embedded systems. I have knowledge in working with APIs and can assist in using and creating API's for various systems and services."
    }/*,
    { 
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    }*/
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/*navigates to service descriptions*/}
                            <a href="/#service">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
