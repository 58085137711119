import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
    image: 'image-1',
    category: 'Development',
    title: 'News Narrator',
    url: 'https://www.youtube.com/@nursunews/videos',
    url2: "https://github.com/Icerewl/Description-of-news/blob/main/README.md"
    },
    {
    image: 'image-2',
    category: 'Development',
    title: 'Affiliate Scraper Pro',
    url: 'https://www.pinterest.de/AffiliateScraperPro/',
    url2: "https://github.com/Icerewl/Affiliate-Scraper-Pro"
    }/*,
    {
    image: 'image-3',
    category: 'Development',
    title: 'Getting tickets to the big show',
    url: '/project3',
    url2: "asd"
    },
    {
    image: 'image-4',
    category: 'Development',
    title: 'Getting tickets to the big show',
    url: '/project4',
    url2: "asd"
    },
    {
    image: 'image-3',
    category: 'Development',
    title: 'Getting tickets to the big show',
    url: '/project5',
    url2: "asd"
    },
    {
    image: 'image-4',
    category: 'Development',
    title: 'Getting tickets to the big show',
    url: '/project6',
    url2: "asd"
    }*/
]
    
    

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                                        <div className="inner">
                                <p>{value.category}</p>
                                <h4><Link to={value.url}>{value.title}</Link></h4>
                                <div className="portfolio-button" style={{display: "flex", justifyContent: "space-between"}}>
                                    <a className="rn-btn" href={value.url} target="_blank" rel="noopener noreferrer">To website</a>
                                    <a className="rn-btn" href={value.url2} target="_blank" rel="noopener noreferrer">Github Repo</a>
                                </div>
                            </div>
                            </div>
                            <Link className="link-overlay" to={value.url} target="_blank" rel="noopener noreferrer"></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;